import * as React from "react"
import { Link } from "gatsby"
import SSRProvider from 'react-bootstrap/SSRProvider';
import { Container } from "react-bootstrap"
import bgImg from "../images/background.png"
import Navbar from "./Navbar"


const Layout = ({ children }) => {
  React.useEffect(() => {
    function scrollFunction() {
      if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
        document.getElementById("impressum-link-bottom").style.visibility = "visible";
        document.getElementById("link-navbar").style.background = "white";
      } else {
        document.getElementById("impressum-link-bottom").style.visibility = "hidden";
        document.getElementById("link-navbar").style.background = "none";
      }
    }
    window.onscroll = function() { scrollFunction() };
    window.onresize = function() { scrollFunction() };
  }, []);

  // to assure gray background on page load, change background image not before component mounted
  // otherwise it is white during image load
  React.useEffect(() => {
    document.documentElement.style.backgroundImage = `url(${bgImg})`;
  }, []);

  const ImprintLinkNavbar = () => (
    <div id="impressum-link-navbar">
      <Link to="/impressum">
        Kontakt | Impressum | Datenschutz
      </Link>
    </div>
  )

  const ImprintLinkBottom = () => (
    <div id="impressum-link-bottom">
      <Link to="/impressum">
        Kontakt | Impressum | Datenschutz
      </Link>
    </div>
  )

  return (
    <SSRProvider>
      <ImprintLinkBottom />
      <Container className="main-container">
        <ImprintLinkNavbar />
        <Navbar />
        {children}
      </Container>
      {/* Matomo Image Tracker */}
      <img referrerpolicy="no-referrer-when-downgrade" src="https://coderat.matomo.cloud/matomo.php?idsite=3&amp;rec=1" style={{ border:0 }} alt="" />
      {/* End Matomo */}
    </SSRProvider>
  )
}

export default Layout
